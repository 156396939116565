{{ set enableSubtitle = subtitle && subtitle.type === 'webvtt' }}
<video
    class="dplayer-video {{ if current }}dplayer-video-current{{ /if }}"
    webkit-playsinline
    {{ if airplay }} x-webkit-airplay="allow" {{ /if }}
    playsinline
    {{ if pic }}poster="{{ pic }}"{{ /if }}
    {{ if crossOrigin !== null || screenshot || enableSubtitle }}crossorigin="{{ crossOrigin ? crossOrigin : 'anonymous' }}"{{ /if }}
    {{ if preload }}preload="{{ preload }}"{{ /if }}
    {{ if url }}src="{{ url }}"{{ /if }}
    >
    {{ if enableSubtitle }}
    <track kind="metadata" default src="{{ subtitle.url }}"></track>
    {{ /if }}
</video>
